import React from "react";
import { Table, Button, Modal, Row, Col,  } from "react-materialize";
import Divider from "react-materialize/lib/Divider";
import lokal11 from "./svg/M.1.1 .svg"
import lokal12 from "./svg/M.1.2.svg"
import lokal21 from "./svg/M.2.1 .svg"
import lokal22 from "./svg/M.2.2.svg"
import lokal23 from "./svg/M.2.3.svg"
import lokal24 from "./svg/M.2.3.svg"
import lokal31 from "./svg/M.3.2.svg"
import lokal32 from "./svg/M.3.2.svg"
import lokal33 from "./svg/M.3.3.svg"
import lokal34 from "./svg/M.3.3.svg"
import lokal41 from "./svg/M.3.3.svg"
import lokal42 from "./svg/M.3.3.svg"
import lokal43 from "./svg/M.3.3.svg"


const Lista = [
  {
    Rzut: (<Row>RZUT</Row>)
      
    
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal11} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"  small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal12} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1sad"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal21} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M123d"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal22} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1cvf"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal23} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal24} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1sad"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal31} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button disabled flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal32} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M12"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal33} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M122"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal34} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M12222"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal23} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal23} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1213"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal23} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  
];
export default Lista;
